// HACK: Since Next.js doesn't have a way to run code with the server starts,
// accessing the contracts through this module ensures that flow.init is called
// with the proper environment.
export {
  arenaboyz,
  abgame,
} from '@crypthulhu/ab-contracts'

import {init} from '@crypthulhu/ab-contracts'
init({
  CHAIN_ENV: process.env.CHAIN_ENV,

  ACCESS_API: process.env.ACCESS_API,
  WALLET_DISCOVERY: process.env.WALLET_DISCOVERY,
  WALLET_DISCOVERY_METHOD: process.env.WALLET_DISCOVERY_METHOD,

  APP_DETAIL_TITLE: process.env.APP_DETAIL_TITLE,
  APP_DETAIL_ICON: process.env.APP_DETAIL_ICON,

  ARENABOYZ_ADDRESS: process.env.ARENABOYZ_ADDRESS,
  ABROYALEGAME_ADDRESS: process.env.ABROYALEGAME_ADDRESS,
  ARENABOYZGLOBALS_ADDRESS: process.env.ARENABOYZGLOBALS_ADDRESS,
  ARENABOYZHISTORY_ADDRESS: process.env.ARENABOYZHISTORY_ADDRESS,
  METADATAVIEWS_ADDRESS: process.env.METADATAVIEWS_ADDRESS,
  NONFUNGIBLETOKEN_ADDRESS: process.env.NONFUNGIBLETOKEN_ADDRESS,
  FUNGIBLETOKEN_ADDRESS: process.env.FUNGIBLETOKEN_ADDRESS,
  FLOWTOKEN_ADDRESS: process.env.FLOWTOKEN_ADDRESS,
})