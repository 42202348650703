import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'

import * as flow from './flow.mjs'
import arenaBoyzGlobalsCdc from './cadence/contracts/ArenaBoyzGlobals.cdc'
import initializeHeroesCdc from './cadence/transactions/arenaboyz/initialize_heroes.cdc'
import resolveAttacksCdc from './cadence/transactions/arenaboyz/resolve_attacks.cdc'
import resetTurnCdc from './cadence/transactions/arenaboyz/reset_turn.cdc'
import signUpCdc from './cadence/transactions/arenaboyz/sign_up.cdc'
import updateKeyCdc from './cadence/transactions/arenaboyz/update_key.cdc'
import submitAttackCdc from './cadence/transactions/arenaboyz/submit_attack.cdc'
import dropItemCdc from './cadence/transactions/arenaboyz/drop_item.cdc'
import equipItemCdc from './cadence/transactions/arenaboyz/equip_item.cdc'
import destroyPlayerKeyCdc from './cadence/transactions/arenaboyz/destroy_player_key.cdc'
import resetGameCdc from './cadence/transactions/arenaboyz/reset_game.cdc'

import getHeroStatsCdc from './cadence/scripts/arenaboyz/get_hero_stats.cdc'
import getPodInfoCdc from './cadence/scripts/arenaboyz/get_pod_info.cdc'
import getPendingAttacksCdc from './cadence/scripts/arenaboyz/get_pending_attacks.cdc'
import getPendingAttacksCountCdc from './cadence/scripts/arenaboyz/get_pending_attacks_count.cdc'
import getTurnEndTimestampCdc from './cadence/scripts/arenaboyz/get_turn_end_timestamp.cdc'
import getTimestampCdc from './cadence/scripts/arenaboyz/get_timestamp.cdc'
import getGameIdCdc from './cadence/scripts/arenaboyz/get_game_id.cdc'
import getGameStartedCdc from './cadence/scripts/arenaboyz/get_game_started.cdc'
import getTurnLengthCdc from './cadence/scripts/arenaboyz/get_turn_length.cdc'
import getPlayerIDCdc from './cadence/scripts/arenaboyz/get_player_id.cdc'
import getEntryFeeCdc from './cadence/scripts/arenaboyz/get_entry_fee.cdc'
import getSecondsPerTurnCdc from './cadence/scripts/arenaboyz/get_seconds_per_turn.cdc'
import getKeyIDCdc from './cadence/scripts/arenaboyz/get_key_id.cdc'
import getGameResultsCdc from './cadence/scripts/arenaboyz/get_game_results.cdc'

import getCurrentTurnCdc from './cadence/scripts/arenaboyz/get_current_turn.cdc'
import getTurnAndPodCountCdc from './cadence/scripts/arenaboyz/get_turn_and_pod_count.cdc'
import getPodCountCdc from './cadence/scripts/arenaboyz/get_pod_count.cdc'
import getEnrolledPlayersCdc from './cadence/scripts/arenaboyz/get_enrolled_players.cdc'
import getHeroesPerPodCdc from './cadence/scripts/arenaboyz/get_heroes_per_pod.cdc'
import getPodOneScavengePoolCdc from './cadence/scripts/arenaboyz/get_pod_one_scavenge_pool.cdc'
//FCL functions

function extractInitBlock(cadenceContent) {
  const initBlockPattern = /init\s*\(\)\s*{([^}]*)}/s;
  const match = initBlockPattern.exec(cadenceContent);
  return match ? match[1].trim() : null;
}

function readStaticValuesFromCadenceContent(cadenceContent) {
  const initBlockContent = extractInitBlock(cadenceContent);
  if (!initBlockContent) {
    console.log("No init() block found in the content.");
    return {};
  }

  const staticValuePattern = /\s*self\.(\w+)\s*=\s*([^;]+);/g;
  let match;
  const values = {};

  while ((match = staticValuePattern.exec(initBlockContent)) !== null) {
    const [_, name, value] = match;
    values[name] = value.trim();
  }

  return values;
}

// Using the imported Cadence content directly
const arenaBoyzGlobals = readStaticValuesFromCadenceContent(arenaBoyzGlobalsCdc)

export function getContractGlobals() {
  return arenaBoyzGlobals
}
export async function getAttackResultEvents(loopCount = 1) {
  var eventType = `A.f8d6e0586b0a20c7.ABRoyaleGame.AttackResultEvent`;
  if (process.env.CHAIN_ENV == 'testnet') {
    eventType = `A.6e7cad7066b272e9.ABRoyaleGame.AttackResultEvent`;
  } else if (process.env.CHAIN_ENV == 'mainnet') {
    eventType = `A.6e7cad7066b272e9.ABRoyaleGame.AttackResultEvent`;
  }

  try {
    const latestBlockResponse = await fcl.send([fcl.getBlock()]);
    const latestBlock = await fcl.decode(latestBlockResponse);

    if (!latestBlock || !latestBlock.height) {
      console.error("Failed to fetch the latest block height.");
      return null;
    }

    let latestBlockHeight = latestBlock.height;
    const allProcessedEvents = [];

    for (let i = 0; i < loopCount; i++) {
      const startBlock = Math.max(0, latestBlockHeight - 200);
      const response = await fcl.send([
        fcl.getEvents(eventType, startBlock, latestBlockHeight)
      ]);
      const events = await fcl.decode(response);

      let lastTimestamp = 0;
      const processedEvents = events.reduce((acc, event) => {
        const baseTimestamp = Math.floor(new Date(event.blockTimestamp).getTime() / 1000);
        const timestamp = lastTimestamp && lastTimestamp >= baseTimestamp ? lastTimestamp + 1 : baseTimestamp;
        lastTimestamp = timestamp;
        acc.push({
          ...event.data,
          critical: event.data.critical ? '1' : '0',
          counter_critical: event.data.counter_critical ? '1' : '0',
          timestamp: timestamp.toString()
        });
        return acc;
      }, []);

      allProcessedEvents.push(...processedEvents);

      latestBlockHeight = startBlock - 1;
    }

    allProcessedEvents.sort((a, b) => parseFloat(a.timestamp) - parseFloat(b.timestamp));

    return allProcessedEvents;
  } catch (error) {
    console.error("Failed to fetch events or latest block:", error);
    return null;
  }
}


(async () => {
  try {
    const result = await getAttackResultEvents();
    console.log('Result:', result);
  } catch (error) {
    console.error('Error calling getAttackResultEvents:', error);
  }
})();

export async function getGameResultEvents() {
  // This function acts as an indexer to look on the chain for any recent attack results so we can play animations or add them to attack history.
// It returns an incredibly clean data structure to play nice with Unity, but also React pages.

var eventType = `A.f8d6e0586b0a20c7.ABRoyaleGame.GameResultEvent`;
if (process.env.CHAIN_ENV == 'testnet') {
  eventType = `A.6e7cad7066b272e9.ABRoyaleGame.GameResultEvent`;
}
else if (process.env.CHAIN_ENV == 'mainnet') {
  eventType = `A.6e7cad7066b272e9.ABRoyaleGame.GameResultEvent`;
}

try {
  const latestBlockResponse = await fcl.send([fcl.getBlock()]);
  const latestBlock = await fcl.decode(latestBlockResponse);

  if (!latestBlock || !latestBlock.height) {
    console.error("Failed to fetch the latest block height.");
    return null;
  }

  const latestBlockHeight = latestBlock.height;
  const startBlock = Math.max(0, latestBlockHeight - 200);

  const response = await fcl.send([
    fcl.getEvents(eventType, startBlock, latestBlockHeight)
  ]);
  const events = await fcl.decode(response);

  let lastTimestamp = 0;
  const processedEvents = events.reduce((acc, event) => {
    const baseTimestamp = Math.floor(new Date(event.blockTimestamp).getTime() / 1000);
    const timestamp = lastTimestamp && lastTimestamp >= baseTimestamp ? lastTimestamp + 1 : baseTimestamp;
    lastTimestamp = timestamp;  // Update last timestamp used
    acc.push({
      ...event.data,
      timestamp: timestamp.toString()
    });
    return acc;
  }, []);

  return processedEvents;
} catch (error) {
  console.error("Failed to fetch events or latest block:", error);
  return null;
}
} 

(async () => {
try {
  const result = await getGameResultEvents();
  console.log('Result:', result);
} catch (error) {
  console.error('Error calling getGameEndEvents:', error);
}
})();

// Transactions
export async function signUp() {
  return await flow.sendTx({
    cadence: signUpCdc
  })
}export async function updateKey() {
  return await flow.sendTx({
    cadence: updateKeyCdc
  })
}
export async function initializeHeroes() {
  return await flow.sendTx({
    cadence: initializeHeroesCdc
  })
}

export async function resolveAttacks() {
  return await flow.sendTx({
    cadence: resolveAttacksCdc
  })
}

export async function resetGame() {
  return await flow.sendTx({
    cadence: resetGameCdc
  })
}

export async function destroyPlayerKey() {
  return await flow.sendTx({
    cadence: destroyPlayerKeyCdc
  })
}
export async function resetTurn() {
  return await flow.sendTx({
    cadence: resetTurnCdc
  })
}
export async function getGameResults() {
  return await flow.sendQuery({
    cadence: getGameResultsCdc
  })
}
export async function submitAttack({attack_target, action_target,scavenge_choice,equip_primary}) {
  return await flow.sendTx({
    cadence: submitAttackCdc,
    args: (arg, t) => [
      arg(attack_target,t.UInt32),
      arg(action_target,t.UInt32),
      arg(scavenge_choice,t.UInt8),
      arg(equip_primary,t.Bool),
    ],
  })
}
export async function submitAttackWebGL(attack_target, action_target,scavenge_choice,equip_primary) {
  return await flow.sendTx({
    cadence: submitAttackCdc,
    args: (arg, t) =>  [
      arg(attack_target,t.UInt32),
      arg(action_target,t.UInt32),
      arg(scavenge_choice,t.UInt8),
      arg(equip_primary,t.Bool),
    ],
  })
}


export async function dropItem(item_index) {
  return await flow.sendTx({
    cadence: dropItemCdc,
    args: (arg, t) => [arg(item_index,t.Int)],
  })
}

export async function equipItem(item_index) {
  return await flow.sendTx({
    cadence: equipItemCdc,
    args: (arg, t) => [arg(item_index,t.Int)],
    
  })
}

// Scripts
export async function getHeroStats(heroID) {
  return await flow.sendQuery({
    cadence: getHeroStatsCdc,
    args: (arg, t) => [arg(heroID,t.UInt32)],
  })
}
export async function getEnrolledPlayers() {
  return await flow.sendQuery({
    cadence: getEnrolledPlayersCdc
  })
}
export async function getHeroesPerPod() {
  return await flow.sendQuery({
    cadence: getHeroesPerPodCdc
  })
}
export async function getCurrentTurn() {
  return await flow.sendQuery({
    cadence: getCurrentTurnCdc
  })
}
export async function getEntryFee() {
  return await flow.sendQuery({
    cadence: getEntryFeeCdc
  })
}
export async function getTurnAndPodCount() {
  return await flow.sendQuery({
    cadence: getTurnAndPodCountCdc
  })
}
export async function getPodInfo(heroID) {
  return await flow.sendQuery({
    cadence: getPodInfoCdc,
    args: (arg, t) => [arg(heroID,t.UInt32)],
  })
}
export async function getPodOneScavengePool(heroID) {
  return await flow.sendQuery({
    cadence: getPodOneScavengePoolCdc,
    args: (arg, t) => [arg(heroID,t.UInt32)],
  })
}
export async function getPendingAttacks() {
  return await flow.sendQuery({
    cadence: getPendingAttacksCdc
  })
}
export async function getPendingAttacksCount() {
  return await flow.sendQuery({
    cadence: getPendingAttacksCountCdc
  })
}
export async function getPodCount() {
  return await flow.sendQuery({
    cadence: getPodCountCdc
  })
}
export async function getSecondsPerTurn() {
  return await flow.sendQuery({
    cadence: getSecondsPerTurnCdc
  })
}
export async function getTurnEndTimestamp() {
  return await flow.sendQuery({
    cadence: getTurnEndTimestampCdc
  })
}
export async function getTimestamp() {
  return await flow.sendQuery({
    cadence: getTimestampCdc
  })
}
export async function getGameStarted() {
  return await flow.sendQuery({
    cadence: getGameStartedCdc
  })
}
export async function getGameID() {
  return await flow.sendQuery({
    cadence: getGameIdCdc
  })
}
export async function getTurnLength() {
  return await flow.sendQuery({
    cadence: getTurnLengthCdc
  })
}

export async function getPlayerID(address) {
  return await flow.sendQuery({
    cadence: getPlayerIDCdc,
    args: (arg, t) => [arg(address, t.Address)],
  })
}

export async function getKeyID(address) {
  return await flow.sendQuery({
    cadence: getKeyIDCdc,
    args: (arg, t) => [arg(address, t.Address)],
  })
}

export async function getKeyIDVerification(address) {
  return await flow.sendQuery({
    cadence: getKeyIDCdc,
    args: (arg, t) => [arg(address, t.Address)],
  })
}
