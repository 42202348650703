import * as fcl from '@onflow/fcl'
import * as flow from './flow.mjs'

//export * as arenaboyz from './arenaboyz.js'
export * as abgame from './abgame.js'
export * as abgameAdmin from './abgameAdmin.js'

export async function init(env) {
  const fclConfig = {
    'accessNode.api': env.ACCESS_API,
    'discovery.wallet': env.WALLET_DISCOVERY,
    'discovery.wallet.method': env.WALLET_DISCOVERY_METHOD,
    'walletconnect.projectId': '4b887fe0fa8ed0537dd81e0dfb612463',
    
    'app.detail.title': env.APP_DETAIL_TITLE,
    'app.detail.icon': env.APP_DETAIL_ICON,

    
    '0xArenaBoyz': env.ARENABOYZ_ADDRESS,
    '0xABRoyaleGame': env.ABROYALEGAME_ADDRESS,
    '0xArenaBoyzGlobals': env.ARENABOYZGLOBALS_ADDRESS,
    '0xArenaBoyzHistory': env.ARENABOYZHISTORY_ADDRESS,
    '0xFungibleToken': env.FUNGIBLETOKEN_ADDRESS,
    '0xFlowToken': env.FLOWTOKEN_ADDRESS,
    '0xNonFungibleToken': env.NONFUNGIBLETOKEN_ADDRESS,
    '0xMetadataViews': env.METADATAVIEWS_ADDRESS
  }

  console.log('CHAIN_ENV', env.CHAIN_ENV)
  console.log('fcl.config', fclConfig)

  flow.init(env)
  fcl.config(fclConfig)
  fcl.currentUser().subscribe((user) => fclUser = user)
}

let fclUser

export async function isLoggedIn() {
  var res = fclUser?.addr
  return res
}
